import { Component, HostListener, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  layoutConfiguration: any;

  // classes
  shrinked: boolean;

  themes: any[] = [];

  scrollY: number = 0;

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.scrollY = window.scrollY;

    this.onScrollEvent(this.scrollY);
  }

  constructor(
    private layoutService: LayoutService,
    private themeService: ThemeService
  ) { }

  ngOnInit(): void {
    this.layoutConfiguration = this.layoutService.layoutConfiguration;
    this.themes = this.themeService.themes;
  }

  onScrollEvent(offset: number) {

    if (this.layoutConfiguration.headerOptions.headerShrinkOnScroll) {

      if (offset > 0) {
        this.shrinked = true;
      } else {
        this.shrinked = false;
      }
    }
  }

}
