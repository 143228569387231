<div
  class="top-level-layout"
  [ngClass]="{
    'with-header': layoutConfiguration.showHeader,
    'with-sidebar': layoutConfiguration.showSidebar,
    'with-footer': layoutConfiguration.showFooter,
    'with-background-shape': layoutConfiguration.globalScope.showBackgroundShape
  }"
>
  <app-header *ngIf="layoutConfiguration.showHeader"></app-header>
  <app-sidebar *ngIf="layoutConfiguration.showSidebar"></app-sidebar>

  <div class="top-level-layout-router-wrapper">
    <div class="page error-page">
      <div class="container">
        <div class="container-shape"></div>
        <div class="container-content">
          <div class="win">
            <div class="main">
              <div [ngSwitch]="errorStatus">
                <div *ngSwitchCase="403">
                  <h1>403: Access Forbidden</h1>
                  <p>
                    Looks like you don’t have permission to this screen. If you believe
                    this is wrong, please contact the competition administrator at
                    <a [href]="mailto(supportEmail, 'Access Forbidden')">
                      {{ supportEmail }}</a>.
                  </p>
                </div>
                <div *ngSwitchDefault>
                  <h1>404: Page Not Found</h1>
                  <p>Oops, we can’t seem to find the page you’re looking for.</p>
                </div>
              </div>
              <a class="button button-primary" routerLink="{{ currentClientService.routePrefix }}/">Go to Homepage</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer *ngIf="layoutConfiguration.showFooter"></app-footer>
</div>
