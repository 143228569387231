import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { LoginComponent } from './login/login.component';
// import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
// import { ProfileComponent } from './profile/profile.component';
// import { RegisterComponent } from './register/register.component';

const routes: Routes = [
  // { path:'login', component: LoginComponent},
  // { path:'change-password', component: ChangePasswordComponent},
  // { path:'register', component: RegisterComponent},
  // { path:'forgot-password', component: ForgotPasswordComponent},
  // { path:'profile', component: ProfileComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginWidgetRoutingModule { }
