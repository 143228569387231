<div
  class="top-level-layout"
  [ngClass]="{
    'with-header': layoutConfiguration.showHeader,
    'with-sidebar': layoutConfiguration.showSidebar,
    'with-footer': layoutConfiguration.showFooter,
    'with-background-shape': layoutConfiguration.globalScope.showBackgroundShape
  }"
>
  <app-header *ngIf="layoutConfiguration.showHeader"></app-header>
  <app-sidebar *ngIf="layoutConfiguration.showSidebar"></app-sidebar>

  <div class="top-level-layout-router-wrapper">
    <div class="page user-management">
      <div class="container">
        <div class="page-loader">
          <div class="loader-inner"></div>
        </div>
        <div class="container-shape"></div>
        <div class="container-content">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>

  <app-footer *ngIf="layoutConfiguration.showFooter"></app-footer>
</div>