import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  layoutConfiguration: any;
  themes: any[] = [];

  constructor(
    private layoutService: LayoutService,
    private themeService: ThemeService
  ) { }

  ngOnInit(): void {
    this.layoutConfiguration = this.layoutService.layoutConfiguration;
    this.themes = this.themeService.themes;
  }

}
