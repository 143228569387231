<div
  class="header-wrapper"
  [ngClass]="{
    'sidebar-enabled': layoutConfiguration.showSidebar,
    'header-fixed': layoutConfiguration.headerOptions.headerFixedPosition,
    'shrinked': shrinked
  }"
>
  <div class="header-holder">
    <app-header-initial *ngIf="layoutConfiguration.headerOptions.headerType === 'initial'"></app-header-initial>
    <app-header-second *ngIf="layoutConfiguration.headerOptions.headerType === 'second'"></app-header-second>
  </div>
</div>
