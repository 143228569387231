import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopLevelLayoutComponent } from './top-level-layout.component';
import { TopLevelLayoutRoutingModule } from './top-level-layout-routing.module';
import { HeaderModule } from '../shared/components/headers/header.module';
import { SidebarModule } from '../shared/components/sidebars/sidebar.module';
import { FooterModule } from '../shared/components/footers/footer.module';



@NgModule({
  declarations: [
    TopLevelLayoutComponent
  ],
  imports: [
    CommonModule,
    TopLevelLayoutRoutingModule,
    HeaderModule,
    SidebarModule,
    FooterModule,
  ],
  exports: [
    TopLevelLayoutComponent,
    HeaderModule,
    SidebarModule,
    FooterModule
  ]
})
export class TopLevelLayoutModule { }
