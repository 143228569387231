import { registerLocaleData } from "@angular/common";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { from, lastValueFrom, Observable } from "rxjs";
import { AuthTokenService } from "./auth-token.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor{

    constructor(
        private authTokenService: AuthTokenService
    ){

    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // convert promise to observable using 'from' operator
        return from(this.handle(req, next))
      }
    
    async handle(req: HttpRequest<any>, next: HttpHandler) {
        // if your getAuthToken() function declared as "async getAuthToken() {}"
        const jwt = await this.authTokenService.getJwt()
    
        // if your getAuthToken() function declared to return an observable then you can use
        // await this.auth.getAuthToken().toPromise()
    
        if(jwt) {
            const cloned = req.clone({
                headers:req.headers
                    .set('Authorization', jwt)
            })

            return lastValueFrom(next.handle(cloned));
        } else{ 
            
            return lastValueFrom(next.handle(req));
        }
    }
}