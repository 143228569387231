import { Component, inject, OnInit } from '@angular/core';
import { Auth, signOut } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { CurrentClientService } from 'src/app/services/current-client.service';
import { UserAdminService } from 'src/app/services/user-admin.service';
import { TabTitleService } from 'src/app/shared/services/tab-title.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  private fireAuth: Auth = inject(Auth);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userAdminService: UserAdminService,
    private tabTitleService: TabTitleService,
    private currentClientService: CurrentClientService
  ) { }

  async ngOnInit(): Promise<void> {
    this.tabTitleService.setTabTitle('Logout');
    const redirectUrl = this.route.snapshot.queryParamMap.get("redirectUrl");
    await firstValueFrom(this.userAdminService.deleteSessionCookie());
    await signOut(this.fireAuth);
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      this.router.navigateByUrl(this.currentClientService.routePrefix + "/login");
    }
  }
}