<div class="login-page" [ngClass]="{'initial-loading': showMessage}">
  <div class="login-loader">
    <div class="loader-inner">
      <div class="loader-image">
        <img src="assets/images/carrot-logo-black-cropped.png" alt="">
      </div>
      <div class="loader-progress"></div>
    </div>
  </div>
  <div class="login-widget">
    <div class="login-widget-content">
      <div class="login-widget-branding">
        <img src="/assets/images/carrot-logo.png" alt="SSO logo">
      </div>
      <div class="login-form-wrap" *ngIf="showMessage">
        <div class="info-wrap">
          <div *ngIf="emailVerififed">
            <h1 class="title">Email verified</h1>
            <p class="subtitle">
              You have successfully verified your email.
              <br>
              Please proceed to <a routerLink="{{ currentClientService.routePrefix }}/login" class="button-forgotten-pass">login</a>.
            </p>
          </div>
          <div *ngIf="!emailVerififed">
            <h1 class="title">Email not verified</h1>
            <p class="subtitle">The link has expired or is invalid.</p>
          </div>
          <div class="extra-buttons-wrap">
            <a routerLink="{{ currentClientService.routePrefix }}" class="button-forgotten-pass">Back to Login</a>
            <a routerLink="{{ currentClientService.routePrefix }}/forgot-password" class="button-forgotten-pass">Forgot password?</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="login-page-footer">
    <div class="logo">
      <a href="https://carrot.net" target="_blank">
        <img src="/assets/images/carrot-logo.png" alt="Carrot.net logo">
      </a>
    </div>
    <div class="links">
      <a href="mailto:accounts@carrot.net">Need help?</a>
    </div>
  </footer>
</div>
