<div class="header-second">
  <ul>
    <li>
      <a routerLink="{{ currentClientService.routePrefix }}/create-user">Create User</a>
    </li>
    <li>
      <a routerLink="{{ currentClientService.routePrefix }}/user-management">User Management</a>
    </li>
  </ul>
</div>
