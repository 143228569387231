import { Injectable, Renderer2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ThemeService } from './theme.service';

export interface ILayoutConfiguration {
  // header
  showHeader?: boolean;
  headerOptions?: {
    headerType?: string;
    headerFixedPosition?: boolean;
    headerShrinkOnScroll?: boolean;
  };
  // sidebar
  showSidebar?: boolean;
  sidebarOptions?: {
    sidebarType?: string;
    sidebarOverHeaderAndFooter?: boolean
  };
  // footer
  showFooter?: boolean;
  footerOptions?: {
    footerType?: string;
  };
  // layout options in the global scope
  globalScope?: {
    showBackgroundShape?: boolean;
  };

  // above this comment are actually used properties
  // below are copy/pasted from previous project

  showNavigation?: boolean;
  showLanguageInHeader?: boolean;
  showSearchInHeader?: boolean;
  // showThemePickerInHeader?: boolean;
  showNotificationsInHeader?: boolean;
  showAccountInHeader?: boolean;
  showMenuInHeader?: boolean;
  navigationPos?: string; // side, top
  sidebarStyle?: string; // full, compact, closed
  sidebarCompactToggle?: boolean; // sidebar expandable on hover
  sidebarColor?: string; // Sidebar background color 
  dir?: string; // ltr, rtl
  isMobile?: boolean; // updated automatically
  useBreadcrumb?: boolean; // Breadcrumb enabled/disabled
  breadcrumb?: string; // simple, title
  topbarFixed?: boolean; // Fixed header
  footerFixed?: boolean; // Fixed Footer
  topbarColor?: string; // Header background color 
  footerColor?: string; // Header background color 
  theme: string; // material theme. egret-blue, carrot-navy, egret-dark-purple, egret-dark-pink
  perfectScrollbar?: boolean;
  contentWidth?: string;
}
export interface ILayoutChangeOptions {
  duration?: number;
  transitionClass?: boolean;
}
interface IAdjustScreenOptions {
  browserEvent?: any;
  route?: string;
}

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  public layoutConfiguration: ILayoutConfiguration = {
    theme: 'initial',
  };
  layoutConfigurationSubject = new BehaviorSubject<ILayoutConfiguration>(this.layoutConfiguration);
  layoutConfiguration$ = this.layoutConfigurationSubject.asObservable();
  public isMobile: boolean;
  public currentRoute: string;
//   public fullWidthRoutes = ['shop'];
  public fullWidthRoutes = [];

  constructor(private themeService: ThemeService) {
    this.setAppLayout(
      // ******** SET YOUR LAYOUT OPTIONS HERE *********
      {
        // header
        showHeader: true, // true, false
        headerOptions: {
          headerType: 'initial', // initial, second
          headerFixedPosition: false, // true, false,
          headerShrinkOnScroll: false, // true, false
        },
        // sidebar
        showSidebar: false, // true, false
        sidebarOptions: {
          sidebarType: 'initial', // initial, second
          sidebarOverHeaderAndFooter: true, // true, false
        },
        // footer
        showFooter: true, // true, false
        footerOptions: {
          footerType: 'initial', // initial, second
        },
        globalScope: {
          showBackgroundShape: true, // true, false
        },
        // theme
        theme: 'initial', // initial, carrot-navy, carrot-navy-dark, UGC01

        // above this comment are actually used properties
        // below are copy/pasted from previous project

        
        showNavigation: false,
        showLanguageInHeader: false,
        showSearchInHeader: false,
        // showThemePickerInHeader: false,
        showNotificationsInHeader: false,
        showAccountInHeader: false,
        showMenuInHeader: false,
        navigationPos: 'top', // side, top
        sidebarStyle: 'full', // full, compact, closed
        sidebarColor: 'slate', 
        sidebarCompactToggle: false, // applied when "sidebarStyle" is "compact"
        dir: 'ltr', // ltr, rtl
        useBreadcrumb: false,
        topbarFixed: true,
        footerFixed: false,
        topbarColor: 'white', // 
        footerColor: 'slate', //
        breadcrumb: 'simple', // simple, title
        perfectScrollbar: false,
        contentWidth: 'md' // xlg, lg, md, sm, xs
      }
    );
  }

  setAppLayout(layoutConfiguration: ILayoutConfiguration) {
    this.layoutConfiguration = { ...this.layoutConfiguration, ...layoutConfiguration };
    this.applyTheme(this.layoutConfiguration.theme);
  }

  publishLayoutChange(lc: ILayoutConfiguration, opt: ILayoutChangeOptions = {}) {
    if (this.layoutConfiguration.theme !== lc.theme && lc.theme) {
      this.themeService.changeTheme(this.layoutConfiguration.theme, lc.theme);
    }

    this.layoutConfiguration = Object.assign(this.layoutConfiguration, lc);
    this.layoutConfigurationSubject.next(this.layoutConfiguration);
  }

  applyTheme(theme: string) {
    this.themeService.applyTheme(theme);
  }

  adjustLayout(options: IAdjustScreenOptions = {}) {
    let sidebarStyle: string;
    this.isMobile = this.isSm();
    this.currentRoute = options.route || this.currentRoute;
    sidebarStyle = this.isMobile ? 'closed' : 'full';

    if (this.currentRoute) {
      this.fullWidthRoutes.forEach((route) => {
        if (this.currentRoute.indexOf(route) !== -1) {
          sidebarStyle = 'closed';
        }
      });
    }

    // this.publishLayoutChange({
    //   isMobile: this.isMobile,
    //   sidebarStyle,
    // });
  }
  
  isSm() {
    return window.matchMedia(`(max-width: 959px)`).matches;
  }
}
