import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../shared/services/layout.service';
import { ThemeService } from '../shared/services/theme.service';

@Component({
  selector: 'app-top-level-layout',
  templateUrl: './top-level-layout.component.html',
  styleUrls: ['./top-level-layout.component.scss']
})
export class TopLevelLayoutComponent implements OnInit {

  layoutConfiguration: any;
  themes: any[] = [];

  constructor(
    private layoutService: LayoutService,
    private themeService: ThemeService
  ) { }

  ngOnInit(): void {
    this.layoutConfiguration = this.layoutService.layoutConfiguration;
    this.themes = this.themeService.themes;
  }

}
