import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { TopLevelLayoutComponent } from '../top-level-layout/top-level-layout.component';
// import { TopLevelStructureComponent } from './top-level-structure.component';

const routes: Routes = [
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   component: TopLevelStructureComponent,
  //   children: [
  //     {
  //       path: '',
  //       component: TopLevelLayoutComponent,
  //       loadChildren: () => import('../top-level-layout/top-level-layout.module').then(m => m.TopLevelLayoutModule),
  //       data: { title: 'Session' }
  //     }
  //   ]
  // },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class TopLevelLayoutRoutingModule { }
