<div class="sidebar-initial-wrap">
  <div class="sidebar-initial">
    <ul>
      <li>
        <a *ngIf="(currentUserService.isLoggedIn$ | async) == false" routerLink="{{ currentClientService.routePrefix }}/login">
          <!-- <mat-icon>account_circle</mat-icon> -->
          <span>Login widget</span>
        </a>
      </li>
      <li>
        <a *ngIf="currentUserService.isLoggedIn$ | async" routerLink="{{ currentClientService.routePrefix }}/user-management/apps">
          <mat-icon>account_circle</mat-icon>
          <span>Apps</span>
        </a>
      </li>
      <li>
        <a *ngIf="currentUserService.isLoggedIn$ | async" routerLink="{{ currentClientService.routePrefix }}/user-management/account">
          <mat-icon>account_circle</mat-icon>
          <span>Account NEW</span>
        </a>
      </li>
      <li>
        <a *ngIf="currentUserService.isSsoAdmin$ | async" routerLink="{{ currentClientService.routePrefix }}/user-management">
          <mat-icon>account_circle</mat-icon>
          <span>User management</span>
        </a>
      </li>
      <li>
        <a *ngIf="currentUserService.isLoggedIn$ | async" (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Logout</span>
        </a>
      </li>
    </ul>
  </div>
</div>